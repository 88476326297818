import React from 'react';

const Contact = () => {

  return (
    <section name="contact" className="my-12 text-gray-800 w-full h-full">
      <h2 className="text-center text-3xl md:text-6xl font-bold text-[#206EA6] tracking-widest md:font-normal my-4 lg:mb-8">
        Kontakt
      </h2>
      <div
        className="relative overflow-hidden bg-no-repeat bg-cover"
        style={{
          backgroundPosition: "90%",
          backgroundImage:
            "url('https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')",
          height: "300px",
        }}
      ></div>
      <div className="max-w-[800px] mx-auto text-gray-800 px-4 md:px-12">
        <div
          className="block rounded-lg shadow-lg shadow-blue-200 py-10 md:py-12 px-4 md:px-6"
          style={{
            marginTop: "-100px",
            background: "hsla(0, 0%, 100%, 0.1)",
            backdropFilter: "blur(30px)",
          }}
        >
          <div className="max-w-[500px] mx-auto">
            <form action="https://formsubmit.co/92138f11bdd509b100a763393663c486" method="POST">
              <input type="hidden" name="_captcha" value="false" />
              <input type="hidden" name="_subject" value="Wiadomość ze strony srvideos.pl" />
              <input type="hidden" name="_next" value="https://srvideos.pl/thanx.html"></input>
              <div className="form-group mb-6">
                <input
                  type="text"
                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  name="Imie"
                  placeholder="Imię i nazwisko"
                  required
                />
              </div>
              <div className="form-group mb-6">
                <input
                  type="email"
                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  name="email"
                  placeholder="E-mail"
                  required
                />
              </div>
              <div className="form-group mb-6">
                <textarea
                  className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300
                rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  name="Wiadomosc"
                  rows="3"
                  placeholder="Wiadomość"
                  required
                ></textarea>
              </div>

              <button
                type="submit"
                className="w-full px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg
              focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              >
                Wyślij
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
