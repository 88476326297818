import React, { useState } from "react";
import { ImVideoCamera } from "react-icons/im";
import { BsFacebook, BsInstagram, BsYoutube } from "react-icons/bs";
// import { HiOutlineMail } from "react-icons/hi";
import { FaBars, FaTimes, FaTiktok } from "react-icons/fa";
// import { TbMoodCrazyHappy } from "react-icons/tb";
import { Link } from "react-scroll";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => {
    setNav(!nav);
  };

  return (
    <div className="w-full h-[80px] bg-[#006FAB] text-white flex justify-between items-center px-4 md:px-24 fixed z-50">
      <Link
        className="flex flex-col justify-center items-center h-full cursor-pointer group"
        to="home"
        smooth={true}
        duration={500}
      >
        <ImVideoCamera className="group-hover:text-gray-300 duration-500" size={30} />
        <h3 className="group-hover:text-gray-300 duration-500">SR VIDEOS</h3>
      </Link>

      <div className="hidden md:block">
        <ul className="flex">
          <li className="p-4 hover:underline hover:cursor-pointer">
            <Link to="topName" smooth={true} duration={500}>
              GŁÓWNA
            </Link>
          </li>
          <li className="p-4 hover:underline hover:cursor-pointer">
            <Link to="about" smooth={true} duration={500}>
              O MNIE
            </Link>
          </li>
          <li className="p-4 hover:underline hover:cursor-pointer">
            <Link to="services" smooth={true} duration={500}>
              USŁUGI
            </Link>
          </li>
          <li className="p-4 hover:underline hover:cursor-pointer">
            <Link to="opinions" smooth={true} duration={500}>
              OPINIE
            </Link>
          </li>
          <li className="p-4 hover:underline hover:cursor-pointer">
            <Link to="contact" smooth={true} duration={500}>
              KONTAKT
            </Link>
          </li>
        </ul>
      </div>

      {/* hamburger menu */}
      <div onClick={handleClick} className="md:hidden z-50">
        {nav ? <FaTimes /> : <FaBars />}
      </div>

      {/* mobile menu */}
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center z-40"
        }
      >
        <li className="py-6 text-4xl hover:cursor-pointer">
          <Link onClick={handleClick} to="topName" smooth={true} duration={500}>
            GŁÓWNA
          </Link>
        </li>
        <li className="py-6 text-4xl hover:cursor-pointer">
          <Link onClick={handleClick} to="about" smooth={true} duration={500}>
            O MNIE
          </Link>
        </li>
        <li className="py-6 text-4xl hover:cursor-pointer">
          <Link onClick={handleClick} to="services" smooth={true} duration={500}>
            USŁUGI
          </Link>
        </li>
        <li className="py-6 text-4xl hover:cursor-pointer">
          <Link onClick={handleClick} to="opinions" smooth={true} duration={500}>
            OPINIE
          </Link>
        </li>
        <li className="py-6 text-4xl hover:cursor-pointer">
          <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
            KONTAKT
          </Link>
        </li>
      </ul>

      {/* social icons */}
      <div className="hidden lg:flex fixed flex-col top-[55%] left-0 z-10">
        <ul>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-500">
            <a
              className="flex justify-between items-center w-full text-white"
              href="https://www.facebook.com/SR.VIDEOS.FANPAGE/"
              rel="noreferrer"
              target="_blank"
            >
              Facebook <BsFacebook size={30} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600">
            <a
              className="flex justify-between items-center w-full text-white"
              href="https://www.instagram.com/sr_videos_filmowanie/"
              rel="noreferrer"
              target="_blank"
            >
              Instagram <BsInstagram size={30} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-700">
            <a
              className="flex justify-between items-center w-full text-white"
              href="https://www.youtube.com/@sr_videos"
              rel="noreferrer"
              target="_blank"
            >
              YouTube <BsYoutube size={30} />
            </a>
          </li>
          {/* <li className="w-[180px] h-[60px] flex justify-between items-center ml-[-120px] hover:ml-[-10px] duration-300 bg-blue-800">
            <a
              className="flex justify-between items-center w-full text-white"
              href="https://www.weselezklasa.pl/ogloszenia-weselne/sr-videos-filmowanie-foto-nowoczesny-teledysk-slub,50421/"
              rel="noreferrer"
              target="_blank"
            >
              WeseleZKlasą <FaTiktok size={30} />
            </a>
          </li> */}
          <li className="w-[180px] h-[60px] flex justify-between items-center ml-[-120px] hover:ml-[-10px] duration-300 bg-blue-800">
            <a
              className="flex justify-between items-center w-full text-white"
              href="https://www.tiktok.com/@sr_videos_filmowanie"
              rel="noreferrer"
              target="_blank"
            >
              TikTok <FaTiktok size={30} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
